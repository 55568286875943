.Image{
	cursor: pointer;
	margin: 4px 0;
	vertical-align: middle;
	width: 100%;
}

.ImageModalContainer{
	z-index: 1000;
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: #000000E0;
}

.ImageModal{
	margin: 10vh auto 0;
	display: table-cell;
	vertical-align: middle;
	text-align: center;
	max-width: 70vw;
	max-height: 80vh;
	border: 1px solid #888888;
}

p.Caption{
	text-align: center !important;
	color: #888888;
	padding: 0 0 2px !important;
}

.Close{
	color: #AAAAAA;
	position: fixed;
	top: 2vh;
	right: 2vh;
	font-size: 50px;
	font-weight: 600;
}

.Close:hover, .Close:focus{
	color: #888888;
	text-decoration: none;
	cursor: pointer;
}