.lds-ring{
	display: block;
	position: relative;
	top: calc(50vh - 32px);
	left: calc(50vw - 32px);
	width: 64px;
	height: 64px;
}

.lds-ring div{
	box-sizing: border-box;
	display: block;
	position: absolute;
	width: 51px;
	height: 51px;
	margin: 6px;
	border: 6px solid #7D3924;
	border-radius: 50%;
	animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	border-color: #7D3924 transparent transparent;
}

.lds-ring div:nth-child(1){
	animation-delay: -0.45s;
}

.lds-ring div:nth-child(2){
	animation-delay: -0.3s;
}

.lds-ring div:nth-child(3){
	animation-delay: -0.15s;
}

@keyframes lds-ring{
	0%{
		transform: rotate(0deg);
	}

	100%{
		transform: rotate(360deg);
	}
}

#LoadingAnimation{
	background-color: #FFFFFF;
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 1001;
	transition: 0.8s;
}