.AboutImage{
	width: 100vw;
	height: 20vh;
	overflow: hidden;
}

.AboutImage h1{
	color: #000000;
	top: 0;
	width: 100vw;
	left: 0;
	text-align: center;
	font-weight: 100;
	font-size: 3em;
}

.AboutImage img{
	height: auto;
	width: 100vw;
}