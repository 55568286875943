header{
	z-index: 900;
	top: 0;
	width: 100vw;
	position: fixed;
}

header ul{
	width: 33%;
	float: right;
	margin: 0 10% 0 0;
	list-style-type: none;
}

header a{
	text-decoration: none;
}

header li{
	margin: 0;
	width: 32%;
	cursor: pointer;
	font-weight: 100;
	font-size: 1.5rem;
	text-align: center;
	display: inline-block;
}

header li a{
	transition: padding 0.8s;
	display: block;
	color: #000000;
}

header li a:after{
	width: 0;
	content: "";
	height: 1px;
	display: block;
	transition: 200ms;
	margin: 2px 20% 0;
	background-color: #000000;
}

header li a:hover:after{
	width: 60%;
}

.SmallHeader li a{
	padding-top: 1.5vh;
	padding-bottom: 1.5vh;
}

.LargeHeader li a{
	padding-top: 5vh;
	padding-bottom: 5vh;
}

header img{
	transition: 0.8s;
}

.LargeHeader img{
	height: 8vh;
	margin: 2vh 2vw;
}

.SmallHeader img{
	height: 4vh;
	margin: 1vh 2vw;
}