.ImageColumn{
	flex: 25%;
	max-width: 25%;
	padding: 0 4px;
	box-sizing: border-box;
}

@media screen and (max-width: 800px){
	.ImageColumn{
		flex: 50%;
		max-width: 50%;
	}
}

@media screen and (max-width: 600px){
	.ImageColumn{
		flex: 100%;
		max-width: 100%;
	}
}