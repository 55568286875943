#hamburger{
	position: fixed;
	top: 0;
	right: 0;
	transition-duration: 0.15s;
	z-index: 1000;
}

#hamburger-box{
	width: 50px;
	height: 50px;
	display: inline-block;
	position: relative;
}

#hamburger-inner{
	display: block;
	top: 20px;
}

#hamburger-inner:before{
	top: 10px;
}

#hamburger-inner:after{
	top: 20px;
}

#hamburger-inner, #hamburger-inner:before, #hamburger-inner:after{
	width: 40px;
	height: 4px;
	background-color: #222222;
	border-radius: 8px;
	position: absolute;
	transition-duration: 0.15s;
}

#hamburger-inner:before, #hamburger-inner:after{
	content: "";
}

.is-active #hamburger-inner, .is-active #hamburger-inner:before, .is-active #hamburger-inner:after{
	background-color: #FFFFFF;
}

.LargeHeader #hamburger-inner, .LargeHeader #hamburger-inner:before, .LargeHeader #hamburger-inner:after{
	background-color: #FFFFFF;
}

.is-active #hamburger-inner{
	transform: translate3d(0, 10px, 0) rotate(45deg);
}

.is-active #hamburger-inner:before{
	transform: rotate(-45deg) translate3d(-5.71px, -6px, 0);
	opacity: 0;
}

.is-active #hamburger-inner:after{
	transform: translate3d(0, -20px, 0) rotate(-90deg);
}

#mobileNav{
	transition: top 0.5s;
	background-color: #282828;
	position: fixed;
	width: 100vw;
	height: 100vh;
	z-index: auto;
	padding-top: calc(2rem + 3.85vh);
}

#mobileNav span{
	margin: 1vh 0 3vh;
	font-size: 3rem;
	color: #FFFFFF;
	display: block;
	text-align: center;
	font-weight: 100;
	font-style: italic;
}

#mobileNav a{
	text-decoration: none;
}