.DownArrow{
	cursor: pointer;
	position: fixed;
	transition: right 0.8s;
	z-index: 1000;
	border: solid #000000;
	border-width: 0 3px 3px 0;
	display: inline-block;
	padding: 30px;
	transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
	bottom: 30px;
}