.FullPageParallax{
	width: 100vw;
	height: 100vh;
}

.FullPageParallax .bg-image{
	opacity: 0.7;
}

.FullPageParallax h1, .FullPageParallax h2{
	font-family: Courier New, Courier, Lucida Sans Typewriter, Lucida Typewriter, monospace;
	font-weight: 100;
	right: 5vw;
	top: 35vh;
	color: #222222;
	font-style: italic;
	font-size: 6em;
	text-align: right;
}

.FullPageParallax h1{
	border-bottom: 2px solid #222222;
}

@media screen and (max-width: 1023px){
	.DownArrow{
		display: none;
	}
}