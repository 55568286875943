.ParallaxImage{
	overflow: hidden;
	position: relative;
}

.ParallaxImage > .bg-image{
	min-height: 100%;
	min-width: 100%;
}

.ParallaxImage > *{
	position: absolute;
}