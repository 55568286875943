header{
	z-index: 900;
	top: 0;
	width: 100vw;
	position: fixed;
}

header ul{
	width: 33%;
	float: right;
	margin: 0 10% 0 0;
	list-style-type: none;
}

header a{
	text-decoration: none;
}

header li{
	margin: 0;
	width: 32%;
	cursor: pointer;
	font-weight: 100;
	font-size: 1.5rem;
	text-align: center;
	display: inline-block;
}

header li a{
	transition: padding 0.8s;
	display: block;
	color: #000000;
}

header li a:after{
	width: 0;
	content: "";
	height: 1px;
	display: block;
	transition: 200ms;
	margin: 2px 20% 0;
	background-color: #000000;
}

header li a:hover:after{
	width: 60%;
}

.SmallHeader li a{
	padding-top: 1.5vh;
	padding-bottom: 1.5vh;
}

.LargeHeader li a{
	padding-top: 5vh;
	padding-bottom: 5vh;
}

header img{
	transition: 0.8s;
}

.LargeHeader img{
	height: 8vh;
	margin: 2vh 2vw;
}

.SmallHeader img{
	height: 4vh;
	margin: 1vh 2vw;
}
#hamburger{
	position: fixed;
	top: 0;
	right: 0;
	transition-duration: 0.15s;
	z-index: 1000;
}

#hamburger-box{
	width: 50px;
	height: 50px;
	display: inline-block;
	position: relative;
}

#hamburger-inner{
	display: block;
	top: 20px;
}

#hamburger-inner:before{
	top: 10px;
}

#hamburger-inner:after{
	top: 20px;
}

#hamburger-inner, #hamburger-inner:before, #hamburger-inner:after{
	width: 40px;
	height: 4px;
	background-color: #222222;
	border-radius: 8px;
	position: absolute;
	transition-duration: 0.15s;
}

#hamburger-inner:before, #hamburger-inner:after{
	content: "";
}

.is-active #hamburger-inner, .is-active #hamburger-inner:before, .is-active #hamburger-inner:after{
	background-color: #FFFFFF;
}

.LargeHeader #hamburger-inner, .LargeHeader #hamburger-inner:before, .LargeHeader #hamburger-inner:after{
	background-color: #FFFFFF;
}

.is-active #hamburger-inner{
	-webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
	        transform: translate3d(0, 10px, 0) rotate(45deg);
}

.is-active #hamburger-inner:before{
	-webkit-transform: rotate(-45deg) translate3d(-5.71px, -6px, 0);
	        transform: rotate(-45deg) translate3d(-5.71px, -6px, 0);
	opacity: 0;
}

.is-active #hamburger-inner:after{
	-webkit-transform: translate3d(0, -20px, 0) rotate(-90deg);
	        transform: translate3d(0, -20px, 0) rotate(-90deg);
}

#mobileNav{
	transition: top 0.5s;
	background-color: #282828;
	position: fixed;
	width: 100vw;
	height: 100vh;
	z-index: auto;
	padding-top: calc(2rem + 3.85vh);
}

#mobileNav span{
	margin: 1vh 0 3vh;
	font-size: 3rem;
	color: #FFFFFF;
	display: block;
	text-align: center;
	font-weight: 100;
	font-style: italic;
}

#mobileNav a{
	text-decoration: none;
}
.lds-ring{
	display: block;
	position: relative;
	top: calc(50vh - 32px);
	left: calc(50vw - 32px);
	width: 64px;
	height: 64px;
}

.lds-ring div{
	box-sizing: border-box;
	display: block;
	position: absolute;
	width: 51px;
	height: 51px;
	margin: 6px;
	border: 6px solid #7D3924;
	border-radius: 50%;
	-webkit-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	border-color: #7D3924 transparent transparent;
}

.lds-ring div:nth-child(1){
	-webkit-animation-delay: -0.45s;
	        animation-delay: -0.45s;
}

.lds-ring div:nth-child(2){
	-webkit-animation-delay: -0.3s;
	        animation-delay: -0.3s;
}

.lds-ring div:nth-child(3){
	-webkit-animation-delay: -0.15s;
	        animation-delay: -0.15s;
}

@-webkit-keyframes lds-ring{
	0%{
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}

	100%{
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg);
	}
}

@keyframes lds-ring{
	0%{
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}

	100%{
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg);
	}
}

#LoadingAnimation{
	background-color: #FFFFFF;
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 1001;
	transition: 0.8s;
}
#MainContent{
	font-size: 12px;
	margin-top: 13vh;
}

#MainContent p{
	text-align: left;
	font-size: 1.5em;
	font-weight: 100;
	padding: 10vh 25vw;
}

@media only screen and (max-width: 1024px){
	#MainContent p{
		padding: 10vh 10vw;
	}
}
.ParallaxImage{
	overflow: hidden;
	position: relative;
}

.ParallaxImage > .bg-image{
	min-height: 100%;
	min-width: 100%;
}

.ParallaxImage > *{
	position: absolute;
}
.DownArrow{
	cursor: pointer;
	position: fixed;
	transition: right 0.8s;
	z-index: 1000;
	border: solid #000000;
	border-width: 0 3px 3px 0;
	display: inline-block;
	padding: 30px;
	transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
	bottom: 30px;
}
.FullPageParallax{
	width: 100vw;
	height: 100vh;
}

.FullPageParallax .bg-image{
	opacity: 0.7;
}

.FullPageParallax h1, .FullPageParallax h2{
	font-family: Courier New, Courier, Lucida Sans Typewriter, Lucida Typewriter, monospace;
	font-weight: 100;
	right: 5vw;
	top: 35vh;
	color: #222222;
	font-style: italic;
	font-size: 6em;
	text-align: right;
}

.FullPageParallax h1{
	border-bottom: 2px solid #222222;
}

@media screen and (max-width: 1023px){
	.DownArrow{
		display: none;
	}
}
.Image{
	cursor: pointer;
	margin: 4px 0;
	vertical-align: middle;
	width: 100%;
}

.ImageModalContainer{
	z-index: 1000;
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: #000000E0;
}

.ImageModal{
	margin: 10vh auto 0;
	display: table-cell;
	vertical-align: middle;
	text-align: center;
	max-width: 70vw;
	max-height: 80vh;
	border: 1px solid #888888;
}

p.Caption{
	text-align: center !important;
	color: #888888;
	padding: 0 0 2px !important;
}

.Close{
	color: #AAAAAA;
	position: fixed;
	top: 2vh;
	right: 2vh;
	font-size: 50px;
	font-weight: 600;
}

.Close:hover, .Close:focus{
	color: #888888;
	text-decoration: none;
	cursor: pointer;
}
.ImageColumn{
	flex: 25% 1;
	max-width: 25%;
	padding: 0 4px;
	box-sizing: border-box;
}

@media screen and (max-width: 800px){
	.ImageColumn{
		flex: 50% 1;
		max-width: 50%;
	}
}

@media screen and (max-width: 600px){
	.ImageColumn{
		flex: 100% 1;
		max-width: 100%;
	}
}
#Gallery{
	display: flex;
	flex-wrap: wrap;
	padding: 0 4px;
}
.AboutImage{
	width: 100vw;
	height: 20vh;
	overflow: hidden;
}

.AboutImage h1{
	color: #000000;
	top: 0;
	width: 100vw;
	left: 0;
	text-align: center;
	font-weight: 100;
	font-size: 3em;
}

.AboutImage img{
	height: auto;
	width: 100vw;
}
body{
	margin: 0;
	padding: 0;
	font-family: Segoe UI, Tahoma, Geneva, Verdana, sans-serif;
	overflow-x: hidden;
}
