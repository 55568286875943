#MainContent{
	font-size: 12px;
	margin-top: 13vh;
}

#MainContent p{
	text-align: left;
	font-size: 1.5em;
	font-weight: 100;
	padding: 10vh 25vw;
}

@media only screen and (max-width: 1024px){
	#MainContent p{
		padding: 10vh 10vw;
	}
}